/** @jsxImportSource @emotion/react */
import Layout from "components/Layout"
import { Box, Typography, Backdrop, CircularProgress, Container, Button } from "@mui/material"
import { HomePageStyle } from "appearance"
import { useCallback, useEffect, useState } from "react"
import Theme from "appearance/Theme"
import { getProviders, signIn, useSession } from "next-auth/react"
import { EventEmitter } from "utils/EventUtil"
import { confirmRole } from "services/api/modules/profile"
import { useRouter } from "next/router"

const HomePage = () => {
  // const { data, error } = useSWR(`/api/collections`, AxiosService)
  const { data: session, status }: any = useSession()
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [providers, setProviders] = useState<any>(null)

  const confirmUserRole = useCallback((isArtist: boolean) => {
    setLoading(true)
    confirmRole(isArtist).then(res => {
      if (isArtist) {
        document.location.href = "/collection/create"
      } else {
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (!providers) {
      getProviders().then(providers => setProviders(providers))
    }
  }, [providers])

  useEffect(() => {
    if (router.query.session_state && router.query.code) {
      router.push("/", undefined, { shallow: true })
      setLoading(true)
      getProviders().then(providersObject => {
        const providers = providersObject ? Object.values(providersObject) : null
        if (providers && providers.length > 0) {
          signIn(providers[0].id).then(() => setLoading(false))
        } else {
          setLoading(false)
        }
      })
    }
  }, [router])

  useEffect(() => {
    if (session?.user && !session?.user?.confirmedRole && session.user?.role !== "ADMIN") {
      EventEmitter.emit("OpenActionsPopup", {
        title: "Welcome!",
        message: "Are you interested in creating your own collection?",
        status: "info",
        actions: [
          {
            label: "No",
            onClick: () => confirmUserRole(false),
          },
          {
            label: "Yes",
            onClick: () => confirmUserRole(true),
          },
        ],
      })
    }
  }, [confirmUserRole, session?.user, session?.user?.confirmedRole])

  /* const [collections, setCollections] = useState([])
  const [sortBy, setSortBy] = useState(0)

  useEffect(() => {
    getCollections().then(response => setCollections(response))
  }, [])

  const sort = (sortBy: number) => {
    setSortBy(sortBy)
    switch (sortBy) {
      case 0:
        setCollections(collections.sort((x: any, y: any) => x.name - y.name))
      case 1:
        setCollections(collections.sort((x: any, y: any) => x.createdAt - y.createdAt))
      case 2:
        setCollections(collections.sort((x: any, y: any) => y.createdAt - x.createdAt))
      default:
        break
    }
  } */

  return (
    <Layout title="Home">
      <Box css={HomePageStyle.FeaturedBoxStyle} sx={{ flexDirection: { xs: "column", md: "row" }, padding: { xs: Theme.spacing(6, 2) }, fontWeight: { xs: "bold" } }}>
        <Container maxWidth={false} sx={{ maxWidth: 1420 }}>
          <Typography sx={{ fontSize: 40, fontWeight: "bold", lineHeight: 1.2, marginTop: 20, marginBottom: 2 }}>
            Sign in to view
            <br />
            your badges
            <br />
            or collection info.
          </Typography>
          {!session && <>{providers && Object.values(providers).map((provider: any, index: number) => (index === 0 ? <Button onClick={() => signIn(provider.id)}>Sign in</Button> : <></>))}</>}
        </Container>
      </Box>
      {/* <Box css={HomePageStyle.TitleWrapperStyle}>
        <Typography variant="h1">Latest Drops</Typography>
      </Box>
      <Divider /> */}
      {/* <Container maxWidth={false} sx={{ maxWidth: 1420 }}>
        <Box css={HomePageStyle.SortWrapperStyle}>
          <FormControl>
            <Select
              value={sortBy}
              onChange={e => sort(parseInt(e.target.value.toString()))}
              sx={{ minWidth: 198, height: 39, fontSize: 13, color: alpha("#FFFFFF", 0.5) }}
              IconComponent={() => <Icon className="lnr-chevron-down" sx={{ fontSize: 12, color: alpha("#FFFFFF", 0.5), margin: Theme.spacing(1) }} />}>
              <MenuItem value={0}>Default Sorting</MenuItem>
              <MenuItem value={1}>Most Recent</MenuItem>
              <MenuItem value={2}>Most Pass</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Container>
      <Divider /> */}
      {/* <Container maxWidth={false} sx={{ maxWidth: 1420 }}>
        <Grid container css={HomePageStyle.CollectionGridStyle} spacing={{ xs: 6 }}>
          {data &&
            data
              .map((collection: any) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={collection.id}>
                  <CollectionCard collection={{ ...JSON.parse(collection.crypto_data), id: collection.id }} />
                </Grid>
              ))
              .sort((x: any, y: any) => x.name - y.name)}
        </Grid>
      </Container> */}

      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress />
        </Backdrop>
      )}
    </Layout>
  )
}

export default HomePage
